
import getJSONLD from '~/utils/structured-data';
import getUrl from '~/utils/get-url';
import { commonProps } from '../../utils/prismic';

export default {
  props: commonProps,
  head() {
    const { baseUrl } = getUrl(this.$nuxt.context.req);
    return {
      script: [getJSONLD('productList', { ...this.slice.data, baseUrl })],
    };
  },
};
