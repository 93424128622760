import prismicDOM from 'prismic-dom';
import linkResolver from '~/plugins/prismic/link-resolver';
import { stripHTMLTags, asText } from '~/utils/html';

export default (type, data) => {
  let jsonldData = {};
  const layout = data.context?.$store?.state?.layout || {};
  const headData = {
    ...(data.context?.$i18n.t('configs').head || {}),
    ...layout.head,
  };

  switch (type) {
    case 'breadcrumb':
      jsonldData = {
        '@type': 'BreadcrumbList',
        itemListElement: data.links.map((link, j) => {
          let path;

          if (link?.cta?.field) {
            path = linkResolver(link.cta.field);
          } else if (link?.cta?.href) {
            path = link.cta.href;
          }

          const linkItem =
            data.baseUrl && path
              ? {
                  item: `${data.baseUrl}${path}`,
                }
              : {};

          return {
            '@type': 'ListItem',
            position: j + 1,
            name: link.label,
            ...linkItem,
          };
        }),
      };
      break;

    case 'organization':
      jsonldData = {
        '@type': 'Organization',
        name: headData.title,
        url: data.url,
        logo: data.organization_logo,
        sameAs: data.social_profiles,
      };

      if (data.reviews?.average_rating && data.reviews?.review_count) {
        jsonldData.aggregateRating = {
          '@type': 'AggregateRating',
          ratingValue: data.reviews.average_rating,
          reviewCount: data.reviews.review_count,
        };
      }

      break;

    case 'market':
      jsonldData = {
        '@type': 'Product',
        url: data.url,
        name: data.market.breadcrumb_title || data.market.title,
        description: headData.description,
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: data.reviews.average_rating,
          reviewCount: data.reviews.review_count,
        },
      };
      break;

    case 'faq':
      if (data.isFAQ) {
        jsonldData = {
          '@type': 'FAQPage',
          mainEntity: data.items.map(({ head, text }) => ({
            '@type': 'Question',
            name: stripHTMLTags(head),
            acceptedAnswer: {
              '@type': 'Answer',
              text: stripHTMLTags(text),
            },
          })),
        };
      }
      break;

    case 'article':
      jsonldData = {
        '@type': 'NewsArticle',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': data.url,
        },
        headline: !!data.title && asText(data.title),
        description: !!data.description && asText(data.description),
        image: data?.image ? [data.image.cdn_link] : null,
        datePublished: new Date(data.date).toISOString(),
        dateModified: new Date(data.date).toISOString(),
        author: {
          '@type': 'Organization',
          name: 'APRIL',
          logo: {
            '@type': 'ImageObject',
            url: 'https://assets.april.fr/Common/logos/logo-april-u.svg',
          },
        },
        publisher: {
          '@type': 'Organization',
          name: 'APRIL',
          logo: {
            '@type': 'ImageObject',
            url: 'https://assets.april.fr/Common/logos/logo-april-u.svg',
          },
        },
        articleSection: data.market?.data?.breadcrumb_title,
      };
      break;

    case 'product': {
      const hero = data.slices.find((el) => el.slice_type === 'hero_push');

      const imageObject =
        hero?.data?.carouselItems &&
        hero.data.carouselItems[0]?.imageDesktop.filename
          ? {
              image: hero.data.carouselItems[0].imageDesktop.filename,
            }
          : {};

      const aggregateRatingObject = data?.reviews
        ? {
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: data.reviews.average_rating,
              reviewCount: data.reviews.review_count,
            },
          }
        : {};

      jsonldData = {
        '@type': 'Product',
        url: data.url,
        name: data.product?.title && asText(data.product.title),
        brand: headData.siteName,
        description: headData.description,
        ...imageObject,
        ...aggregateRatingObject,
      };
      break;
    }
    case 'productList': {
      const items =
        data.cards?.length > 0
          ? data.cards.filter((card) => card.type === 'product-card')
          : [];
      if (items.length > 0) {
        jsonldData = {
          '@type': 'ItemList',
          itemListElement: items.map((item, j) => {
            let urlObject = {};
            if (
              item.data?.secondaryLink?.link?.field?.type === 'product_page'
            ) {
              urlObject = {
                url: `${data.baseUrl}${prismicDOM.Link.url(
                  item.data.secondaryLink.link.field,
                  linkResolver
                )}`,
              };
            }

            return {
              '@type': 'ListItem',
              position: j + 1,
              ...urlObject,
            };
          }),
        };
      }
      break;
    }
    case 'contact':
      jsonldData = {
        '@type': 'ContactPoint',
        contactType: `${data.title} - ${data.subhead}`,
        telephone: data.number?.tel,
      };
      break;
    case 'website':
      jsonldData = {
        '@type': 'Website',
        name: data.title,
        description: data.description,
      };
      break;
    default:
      jsonldData = {};
  }

  if (Object.keys(jsonldData).length > 0) {
    return {
      type: 'application/ld+json',
      json: { '@context': 'https://schema.org', ...jsonldData },
    };
  } else {
    return {};
  }
};
